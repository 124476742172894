import React from "react";
import Whatsapp from "../../../images/SVG/Whatsapp";

const Header = ({ data }) => (
  <div className="banner faqs">
    <div className="container">
      <div className="content d-flex flex-wrap">
        <div className="content-lft col-xl-12 col-md-10 col-sm-12">
          <h1
            dangerouslySetInnerHTML={{
              __html: data.title,
            }}
          ></h1>
        </div>
        <div className="whatsapp content-lft col-xl-12 col-md-10 col-sm-12">
          <a href={`${data.whatsapp.path}`} target={data.whatsapp.target}>
            <Whatsapp />
            {data.whatsapp.name}
          </a>
        </div>
      </div>
    </div>
  </div>
);

export { Header };
