import React, { useEffect, useRef, useState } from "react";

const FaqsItems = ({ data, lang }) => {
  const [active, setActive] = useState();
  const [content, setContent] = useState([]);
  const [catSelect, setCatSelect] = useState();

  const searchRef = useRef();

  useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;

    const searchParams = new URLSearchParams(window.location.search);
    const catValue = searchParams.get("cat");
    const isCatValueActive = !!catValue;
    const content = isCatValueActive
      ? data.filter((item) => item.slug === catValue)
      : data;

    setContent(content);

    setCatSelect(isCatValueActive ? content[0].Category : "");
  }, [data]);

  const addActiveClass = (e) => {
    const clicked = e.target.dataset.id;
    setActive(active === clicked ? "" : clicked);
  };

  const filterCat = (cat) => (e) => {
    e.preventDefault();
    const content = data.filter((item) => item.Category === cat);

    setContent(content);
    setCatSelect(cat);

    const slug = `/${lang === "es" ? "contacto" : "en/contact"}/?cat=${
      content[0].slug
    }`;
    const newState = {}; // Optionally, you can pass a state object

    window.history.pushState(newState, "", slug);
  };

  const deletefilterCat = (e) => {
    e.preventDefault();
    setContent(data);
    setCatSelect("");

    const slug = `/${lang === "es" ? "contacto" : "en/contact"}`;
    const newState = {}; // Optionally, you can pass a state object

    window.history.pushState(newState, "", slug);

    //window.location.href = `/${lang === "es" ? "contacto" : "en/contact"}/`
  };

  // search bar functions  --------------------------------------------------------------

  const removeDiacritics = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  const removePlural = (word) => {
    if (word.endsWith("es")) {
      return word.slice(0, -2);
    } else if (word.endsWith("s")) {
      return word.slice(0, -1);
    } else {
      return word;
    }
  };

  const filterFaqsBySearch = (searchTerm) => (faq) => {
    const faqTitle = removeDiacritics(faq.Title.toLowerCase());
    const faqText = removeDiacritics(faq.Text.toLowerCase());
    const searchSingular = searchTerm.replace(/(es|s)$/, ""); // removes 'es' or 's' from the end of searchTerm
    const faqTitlePlural = removePlural(faqTitle);
    const faqTextPlural = removePlural(faqText);

    return (
      faqTitle.includes(searchTerm) ||
      faqText.includes(searchTerm) ||
      faqTitlePlural.includes(searchTerm) ||
      faqTextPlural.includes(searchTerm) ||
      faqTitlePlural.includes(searchSingular) ||
      faqTextPlural.includes(searchSingular)
    );
  };

  const [searchText, setSearchText] = useState("");

  const handleSearch = () => {
    const dataCopy = [...data];
    let value = removeDiacritics(searchRef.current.value.toLowerCase());
    setSearchText(value);

    // filter the words by plural and singular
    const filterBySearchTerm = (element) => {
      const filteredFaqs = element.FaqItem.filter(filterFaqsBySearch(value));

      return filteredFaqs.length > 0
        ? { ...element, FaqItem: filteredFaqs }
        : null;
    };

    // only return values with true on it
    const searchResults = dataCopy.map(filterBySearchTerm).filter(Boolean);

    setContent(searchResults);
  };

  const getHighlightedText = (text, searchTerm) => {
    const regex = new RegExp(searchTerm, "gi");
    const searchInputIsEmpty = searchTerm === "";
    const originalText = text;

    const highlightedText = text.replace(
      regex,
      '<span style="background-color: yellow">$&</span>',
    );

    const content = searchInputIsEmpty ? originalText : highlightedText;

    return { __html: content };
  };

  // ----------------------------------------------------------------------------------

  return (
    <div className="faqs_list">
      <div className="container">
        <div className="search">
          <div className="search-input">
            <span></span>
            <input
              type="text"
              ref={searchRef}
              placeholder={
                lang === "en"
                  ? "How can we help you?"
                  : "¿En qué podemos ayudarte?"
              }
              value={searchText}
              onChange={() => handleSearch()}
            ></input>
          </div>
        </div>
        <ul className="filter-links">
          <li className={catSelect === "" ? "active" : ""}>
            {" "}
            <a
              // href="#"
              onClick={deletefilterCat}
              style={{ cursor: "pointer" }}
            >
              {lang === "en" ? "All" : "Todos"}
            </a>
          </li>
          {data.map((val, index) => {
            return (
              <li
                key={index}
                className={catSelect === val.Category ? "active" : ""}
              >
                {" "}
                <a
                  // href="#"
                  onClick={filterCat(val.Category)}
                  style={{ cursor: "pointer" }}
                >
                  {val.Category}
                </a>
              </li>
            );
          })}
        </ul>
        <div className="faqs_list_into">
          {content.map((val, index) => {
            return (
              <div className="cat_lists" key={index}>
                <h4>{val.Category}</h4>
                {val.FaqItem.map((valu, indexu) => {
                  return (
                    <div
                      className="cat_list"
                      key={indexu}
                      onClick={addActiveClass}
                    >
                      <div data-id={`${index}-${indexu}`}>
                        <span
                          data-id={`${index}-${indexu}`}
                          dangerouslySetInnerHTML={getHighlightedText(
                            valu.Title,
                            searchText,
                          )}
                        />

                        <i
                          className={
                            active === `${index}-${indexu}` ? "open" : ""
                          }
                          data-id={`${index}-${indexu}`}
                        ></i>
                      </div>
                      <div
                        className={
                          active === `${index}-${indexu}` ? "open" : ""
                        }
                        dangerouslySetInnerHTML={getHighlightedText(
                          valu.Text,
                          searchText,
                        )}
                      />
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export { FaqsItems };
