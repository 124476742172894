import React from "react";
import { Header } from "./Header";
import { FaqsItems } from "./FaqsItems";
import { Seo } from "../Layout/Seo";
const Contact = ({ pageContext, lang }) => {
  const { fragments } = pageContext;

  return (
    <Seo
      title={fragments.SeoTitle}
      description={fragments.SeoDescription}
      lang={lang}
    >
      <Header data={fragments.Header} />
      <FaqsItems data={fragments.FaqSection} lang={lang} />
    </Seo>
  );
};

export default Contact;
